<template>
  <div dir="ltr" class="customize__index">
    <div v-if="isLoading">
      <LoaderComp />
    </div>
    <!-- Loader -->
    <!-- All Pages -->
    <div v-else>
      <!-- Design View Start -->
      <div v-if="MainView == 'Design'">
     

        <div class="row  m-0 p-0">

            <div class="col-3 text-left bg-white sidebar p-0 d-flex ">
              <div class=" px-2 pt-3" style="width:65px;height:100%; border-right: 1px solid rgba(0, 0, 0, 0.1);position: sticky; top:0;left:0;">
                <button v-b-tooltip.right="{ variant: 'light' }"
            title="Style"  class="btn sideBarBtns" :class="getCurrSidebarValue == 'pageDesign' ? 'selectedView' : null" @click="setCurrSidebarView('pageDesign')">
                  <svg class="sideBarBtnsIcon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 0L2.5 5.83333L3.85833 6.89167L10 11.6667L16.1333 6.89167L17.5 5.83333L10 0ZM16.1417 8.94167L10 13.725L3.85 8.95L2.5 10L10 15.8333L17.5 10L16.1417 8.94167ZM16.1417 13.1083L10 17.8917L3.85 13.1167L2.5 14.1667L10 20L17.5 14.1667L16.1417 13.1083Z" fill="#73738D"/>
</svg>

            </button>    
                <button  v-b-tooltip.right="{ variant: 'light' }"
            title="Settings"  class="btn sideBarBtns" :class="getCurrSidebarValue == 'pageSettings' ? 'selectedView' : null" @click="setCurrSidebarView('pageSettings')">
                  <svg class="sideBarBtnsIcon" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.99987 11.9167C8.22632 11.9167 7.48445 11.6094 6.93747 11.0624C6.39049 10.5154 6.0832 9.77355 6.0832 9C6.0832 8.22645 6.39049 7.48459 6.93747 6.93761C7.48445 6.39063 8.22632 6.08333 8.99987 6.08333C9.77341 6.08333 10.5153 6.39063 11.0623 6.93761C11.6092 7.48459 11.9165 8.22645 11.9165 9C11.9165 9.77355 11.6092 10.5154 11.0623 11.0624C10.5153 11.6094 9.77341 11.9167 8.99987 11.9167ZM15.1915 9.80833C15.2249 9.54167 15.2499 9.275 15.2499 9C15.2499 8.725 15.2249 8.45 15.1915 8.16667L16.9499 6.80833C17.1082 6.68333 17.1499 6.45833 17.0499 6.275L15.3832 3.39167C15.2832 3.20833 15.0582 3.13333 14.8749 3.20833L12.7999 4.04167C12.3665 3.71667 11.9165 3.43333 11.3915 3.225L11.0832 1.01667C11.0499 0.816668 10.8749 0.666668 10.6665 0.666668H7.3332C7.12487 0.666668 6.94987 0.816668 6.91653 1.01667L6.6082 3.225C6.0832 3.43333 5.6332 3.71667 5.19987 4.04167L3.12487 3.20833C2.94153 3.13333 2.71653 3.20833 2.61653 3.39167L0.949866 6.275C0.841533 6.45833 0.891533 6.68333 1.04987 6.80833L2.8082 8.16667C2.77487 8.45 2.74987 8.725 2.74987 9C2.74987 9.275 2.77487 9.54167 2.8082 9.80833L1.04987 11.1917C0.891533 11.3167 0.841533 11.5417 0.949866 11.725L2.61653 14.6083C2.71653 14.7917 2.94153 14.8583 3.12487 14.7917L5.19987 13.95C5.6332 14.2833 6.0832 14.5667 6.6082 14.775L6.91653 16.9833C6.94987 17.1833 7.12487 17.3333 7.3332 17.3333H10.6665C10.8749 17.3333 11.0499 17.1833 11.0832 16.9833L11.3915 14.775C11.9165 14.5583 12.3665 14.2833 12.7999 13.95L14.8749 14.7917C15.0582 14.8583 15.2832 14.7917 15.3832 14.6083L17.0499 11.725C17.1499 11.5417 17.1082 11.3167 16.9499 11.1917L15.1915 9.80833Z" fill="#73738D"/>
</svg>
            </button>    

              </div>
              <div class="flex-grow-1">
                <EditorSidebar v-if="getCurrSidebarValue == 'pageDesign'" :CurrentSelectedPage="CurrentSelectedPage" :isDesktopView="EditorPageView" @triggerUpgradeModal="openUpgradeModal($event)" @GetAllPages="UpdateSavedPages()"
         
         @UpdateQuiz="UpdateSavedPages()" />

         <PageSettings v-if="getCurrSidebarValue == 'pageSettings'" />
              </div>
             
            </div>

            <div class="col-9  p-0 sidebar">

                <div class="QuizMainContainer d-flex flex-column w-100 h-100">

                  <div class=" w-100 d-flex justify-content-center align-items-center " :style="getContainerFlexGrowValue('mainContainer')">

                    <div  v-if="CurrentSelectedPage.value == 'getStartedPage'"
          class="row m-0"
          dir="ltr"
          :key="countComp + 'getStarted'">
            
            <GetStartedPageCompVue :isDesktopView="EditorPageView" :key="CurrentSelectedPage.id + 'getStarted'" />

                    </div>
                    <div
          v-if="CurrentSelectedPage.value == 'singleAnswer'"
          class="row m-0"
          dir="ltr"
          :key="countComp + 'single'"
        >

       
          <SingleAnswerPage :isDesktopView="EditorPageView" @triggerUpgradeModal="openUpgradeModal($event)" :key="CurrentSelectedPage.id + 'singleAnswer'" />
        </div>

        <div
          v-if="CurrentSelectedPage.value == 'multipleAnswer'"
          class="row m-0"
          dir="ltr"
          :key="countComp + 'multi'"
        >
          <MultipleAnswerPage :isDesktopView="EditorPageView" @triggerUpgradeModal="openUpgradeModal($event)" :key="CurrentSelectedPage.id + 'multipleAnswer'" />
        </div>

         <div
          v-if="CurrentSelectedPage.value == 'leadPage'"
          class="row m-0"
          dir="ltr"
          :key="countComp + 'lead'"
        >
          <LeadPage :isDesktopView="EditorPageView" @triggerUpgradeModal="openUpgradeModal($event)" :key="CurrentSelectedPage.id + 'leadPage'" />
        </div>

        <div
          v-if="CurrentSelectedPage.value == 'stripePage'"
          class="row m-0"
          dir="ltr"
          :key="countComp + 'stripePage'"
        >
          <StripePayment :isDesktopView="EditorPageView" :key="CurrentSelectedPage.id + 'stripePage'" />
        </div>
        <div
          v-if="CurrentSelectedPage.value == 'sliderPage'"
          class="row m-0"
          dir="ltr"
          :key="countComp + 'sliderPage'"
        >
          <SliderPage :isDesktopView="EditorPageView" :key="CurrentSelectedPage.id + 'sliderPage'" />
        </div>

         <div
          v-if="CurrentSelectedPage.value == 'resultPage'"
          class="row m-0"
          dir="ltr"
          :key="countComp + 'result'"
        >
          <ResultPage :isDesktopView="EditorPageView" @triggerUpgradeModal="openUpgradeModal($event)" :key="CurrentSelectedPage.id + 'resultPage'" />
        </div>


        


                  </div>

                  <div class="px-3" :style="getContainerFlexGrowValue('customNav')">
        <CustomQuizNav :isDesktopView="EditorPageView" @togglePreview="toggleTestModal" @GetAllPages="UpdateSavedPages()" ref="CustomQuizNavRef"/>
       </div>
                  
                </div>

                

            </div>

        </div>

       
        <!-- Upgrade Plan Modal Start -->
        <div class="modal fade" id="UpgradePlanModalIndex" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" v-bind="$attrs" ref="UpgradePlanModalIndex">

  <div class="modal-dialog modal-lg">
      <div class="modal-content " id="UpgradePlanModalContentIndex" style=" border-radius:20px;">


          <!-- Modal Content -->
          <div >

            <div class="" >
              <div class="container px-4">
                <div class="row  px-3 py-3 d-flex justify-content-end align-items-center">
                    <div>
                        <button
                        @click="CloseModalUpgrade"
                        class="btn outline-none  outline-none border-0 "
                        style="cusrsor: pointer;color:#323232;"><i class="fa fa-times" aria-hidden="true"></i></button>

                    </div>
                </div>

          <div class="row pb-5">
              <div class="col-12 col-sm-6 col-md-6 d-flex align-items-center justify-content-center">
                  <LazyImage :src="'https://quizell.nyc3.cdn.digitaloceanspaces.com/website/upgradePlanBanner.png'" style="height:250px;width:250px;" alt="" />
              </div>
              <div class="col-12 col-sm-6 col-md-6 ">
                  <div class="row" style="text-align:start !important;">
                      <div class="col-12">
                          <p style="color:#292929;font-weight:600;font-size:22px;">{{upgradeModalTitle}}</p>
                      </div>
                      <div class="col-12">
                          <p style="color:#4d4950;font-weight:400;font-size:16px;" v-html="upgradeModalsubText"></p>
                      </div>
                      <div class="col-12 mt-2">
                          <button @click="openUpgradePlan()" class="px-3 py-2 outline-none  outline-none border-0" style="background:#ffa201;color:#ffffff;border-radius:6px">Upgrade Now</button>
                      </div>
                  </div>
              </div>
          </div>



              </div>
            </div>
          </div>

      </div>
    </div>
</div>
        <!-- Upgrade Plan Modal End -->
      </div>
      <!-- Design View End -->

      <div v-else>
        <ErrorPage />
      </div>
    </div>

    <TestPreviewModal :visible="QuizPreview" @back="QuizPreview=false" :quizKey="QuizKey" />

 
  </div>
</template>

<script>
import $ from 'jquery'
import axios from "axios";
import { mapGetters, mapMutations } from "vuex";
import LoaderComp from "./component/LoaderComp.vue";
import cssFunctionMixin from "./mixin/cssFunction.js";
import ErrorPage from "./component/customize/pages/ErrorPage.vue";
import globalMixin from "../../GlobalMixins/globalMixin.js";

// New Editor Layout
import EditorSidebar from './component/EditorComponents/EditorSidebar.vue';
import PageSettings from './component/EditorComponents/PageSettings.vue';
// import CustomQuizNav from "./component/CustomQuizNav.vue"
export default {
  mixins: [cssFunctionMixin, globalMixin],
  components: {
  
    LoaderComp,
    ErrorPage,
    // New Editor Layout
    EditorSidebar,
    PageSettings,
    // CustomQuizNav,

    CustomQuizNav: () =>
      import(
        /* webpackChunkName: "CustomQuizNav" */ "./component/CustomQuizNav.vue"
      ),
    GetStartedPageCompVue: () =>
      import(
        /* webpackChunkName: "GetStartedPageCompVue" */ "./component/EditorComponents/GetStartedPageComp.vue"
      ),
    SingleAnswerPage: () =>
      import(
        /* webpackChunkName: "SingleAnswerPage" */ "./component/EditorComponents/SingleAnswerPage.vue"
      ),
    MultipleAnswerPage: () =>
      import(
        /* webpackChunkName: "MultipleAnswerPage" */ "./component/EditorComponents/MultipleAnswerPage.vue"
      ),
    LeadPage: () =>
      import(
        /* webpackChunkName: "LeadPage" */ "./component/EditorComponents/LeadPage.vue"
      ),
    StripePayment: () =>
      import(
        /* webpackChunkName: "StripePayment" */ "./component/EditorComponents/StripePayment.vue"
      ),
      SliderPage: () =>
      import(
        /* webpackChunkName: "SliderPage" */ "./component/EditorComponents/SliderPage.vue"
      ),
    ResultPage: () =>
      import(
        /* webpackChunkName: "ResultPage" */ "./component/EditorComponents/ResultPage.vue"
      ),
      TestPreviewModal: () =>
      import(
        /* webpackChunkName: "TestPreviewModal" */ "../QuizTestModal/TestPreviewModal.vue"
      ),
  },
 async beforeRouteLeave(to, from, next) {
   
    if(this.isAnyPageEdited){

      this.$toasted.show("Saving any unsaved progress!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });

     let isDataFinished = await this.$refs.CustomQuizNavRef.saveData()
     if(isDataFinished){
      next()
     }
    }
  },
  data() {
    return {
      QuizPreview:false,
      count: 0,
      countComp: 0,
      isLoading: false,
      Token: "",
      
      MainView: "Design",
      QuizKey: "",
      QuizID: this.$store.state.QuizId,
      upgradeModalTitle:'',
      upgradeModalsubText:''
      // QuizID:''
    };
  },

  computed: {
    ...mapGetters([
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getIsAnyPageEdited",
      "getCurrPageOrder",
      "getEditorPageView",
      "GetTokenFromMetaTag",
      "getQuizID",
      "getNavbarData",
      "getCurrSidebarValue"
    ]),
    CurrentSelectedPage() {
      return this.getSelectedPage;
    },
    isAnyPageEdited() {
      return this.getIsAnyPageEdited;
    },
    EditorPageView() {
      if (this.getEditorPageView == "desktop") {
        return true;
      } else {
        return false;
      }
    },
    UserAccountData() {
      return this.getNavbarData;
    },
     getUserType() {
      return this.getNavbarData.userType
    }
  },
  async created() {

    if(this.$route.query.token){
      localStorage.setItem('user_access_token', this.$route.query.token);
    }


    this.QuizKey = this.$route.params.id;
    this.Token = this.GetTokenFromMetaTag;
    this.QuizID = JSON.parse(localStorage.getItem("QuizID"));
    window.addEventListener("beforeunload", this.beforeWindowUnload);

    //  await this.ResetStoredData()
    await this.RESET_STATE();

     this.unsubscribeUpgradePlansModal = this.$store.subscribe((mutation) => {
      if (mutation.type === 'setUpgradePlansModal') { 
            // Open Modal
            this.openUpgradeModal(mutation.payload)
      }
    });
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.beforeWindowUnload);
    this.setCurrPageOrder(null);

    // Remove the subsribtion to mutation
    this.unsubscribeUpgradePlansModal()

    if(localStorage.getItem("default_result_Page_state")){
        localStorage.removeItem('default_result_Page_state');
    }
    if(localStorage.getItem("judgme_installed")){
        localStorage.removeItem('judgme_installed');
    }
  },
  mounted() {
    $('[data-toggle="tooltip"]').tooltip();
    // this.PopulateVuex();
    this.TriggerValidateQuizKey();

    if(!localStorage.getItem("default_result_Page_state")){
        localStorage.setItem('default_result_Page_state', 'resultPage');
    }
  },

  methods: {
    toggleTestModal(){
      this.QuizPreview = !this.QuizPreview
    },
    openUpgradeModal(obj){
      this.upgradeModalTitle=obj.title
      this.upgradeModalsubText=obj.subText

       $("#UpgradePlanModalIndex").modal({
        backdrop: "static",
        keyboard: false,
        show: true,
      });
      // this.$refs.UpgradeUserPlanModalIndexComp.OpenModal(obj);
    },
   
    CloseModalUpgrade(){
      this.resetUpgradePlansModal()
         $('#UpgradePlanModalIndex').modal('hide')
    },
        openUpgradePlan(){
        this.CloseModalUpgrade()
        let webUsers = ['web','bc','wordpress']
           if( webUsers.includes(this.getUserType)){
            let url = window.location.origin + `/manage/account/pricing`
            window.open(url,"_self")
      }
      if(this.getUserType == 'shopify') {
        // this.$emit("openPlansModalChild")
        const envPath=process.env.VUE_APP_SHOPIFY_UPGRADE_PLAN_PATH;
      const  url= `https://${this.UserAccountData.name}${envPath}manage/subscription/plans`
      window.open(url,"_parent")
      } 
      if(this.getUserType == 'wix'){
         let url = `https://www.wix.com/apps/upgrade/f67f3bd5-3d73-486c-9bb2-618adcc19fb9`
      window.open(url,"_blank")
      }
       
    },
    async TriggerValidateQuizKey() {
      this.isLoading = true;
      const response = await this.ValidateQuizKey(this.$route.params.id);
      if (response.status == 200 && response.data.status == "success") {
        this.QuizID = response.data.data.quizId;
       await this.PopulateVuex();
       await this.setIsAnyPageEdited(false);

      } else {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }
    },

    onLoad() {
      const getValue = localStorage.getItem("customizeQuiz");
      if (getValue == 1) {
        window.close();
      } else {
        localStorage.setItem("customizeQuiz", 1);
      }
    },
    ...mapMutations([
      "setIsAnyPageEdited",
      "ResetStoredData",
      "setCurrPageOrder",
      "setImageUpload",
      "setSelectedPage",
      "setProgressBar",
      "setTotalPages",
      "setSelectedTemplateDesign",
      "setCurrencies",
      "setEditorSettings",
      "setIsloadQuizCustomizationTriggered",
      "setIsUpdateQuizCustomizationTriggered",
      "RESET_STATE",
      "setTranslation",
      "setQuizId",
      "setQuizKey",
      "setLoadQuizAppsIntegrated",
      "resetUpgradePlansModal",
      "setCurrSidebarView"
    ]),
    beforeWindowUnload(e) {
      // localStorage.setItem("customizeQuiz",0)
      if (this.getIsAnyPageEdited) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    // OpenModal() {
    //   this.$refs.jumperLogicModalOutside.OpenModal();
    // },
    
    async PopulateVuex() {
      if (this.QuizID) {
        this.isLoading = true;

        try {
          await this.setIsloadQuizCustomizationTriggered(true);
          let firstPage, progressBarValue;
          const data = {
            quizId: this.QuizID,
          };
          const response = await axios.post(`/loadQuizCustomization`, data);
          const totalPages = this.rearrangeOrder(
            response?.data.data.userQuiz.totalPages
          );
          progressBarValue = response?.data.data.userQuiz.progressBar;
          firstPage = totalPages[0];
          const currencies = response?.data.data.currencies;
          const setting = response?.data.data.setting;
          const QuizID = response?.data.data.quizId;
          const appsIntegrated  = response?.data.data.appsIntegrated;

          await this.setTotalPages(totalPages);
          await this.setSelectedPage(JSON.parse(JSON.stringify(firstPage)));
          await this.setSelectedTemplateDesign(
            response?.data.data.selectedTemplateDesign
          );
          await this.setLoadQuizAppsIntegrated(appsIntegrated
          );
          await this.setCurrencies(currencies);
          await this.setEditorSettings(setting);
          await this.setQuizId(QuizID);
          await this.setQuizKey(this.QuizKey);
          await this.setProgressBar(progressBarValue);
          await this.setTranslation(setting.translation);
        
          await this.setIsloadQuizCustomizationTriggered(false);
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });
            this.MainView = "ErrorView";
          }
          throw error;
        } finally {
          this.isLoading = false;
        }
      }
    },
    async UpdateSavedPages() {
      if (this.QuizID) {
        try {
          await this.setIsUpdateQuizCustomizationTriggered(true);
          let firstPage, progressBarValue;
          const data = {
            // quizKey : this.QuizKey,
            // _token : this.Token
            quizId: this.QuizID,
          };
          const response = await axios.post(`/loadQuizCustomization`, data);
          const totalPages = this.rearrangeOrder(
            response?.data.data.userQuiz.totalPages
          );

          progressBarValue = response?.data.data.userQuiz.progressBar;
          if (totalPages.length > 0 && this.getCurrPageOrder) {
            firstPage = totalPages[this.getCurrPageOrder];
          } else {
            firstPage = totalPages[0];
          }
          const currencies = response?.data.data.currencies;
          const setting = response?.data.data.setting;
          const QuizID = response?.data.data.quizId;
           const appsIntegrated  = response?.data.data.appsIntegrated;
          await this.setTotalPages(totalPages);

          await this.setSelectedPage(JSON.parse(JSON.stringify(firstPage)));
          await this.setSelectedTemplateDesign(
            response?.data.data.selectedTemplateDesign
          );
          await this.setLoadQuizAppsIntegrated(appsIntegrated
          );
          await this.setCurrencies(currencies);
          await this.setEditorSettings(setting);
          await this.setQuizId(QuizID);
          await this.setQuizKey(this.QuizKey);
          await this.setProgressBar(progressBarValue);
          await this.setTranslation(setting.translation);
          await this.setIsUpdateQuizCustomizationTriggered(false);
          await this.setIsAnyPageEdited(false);
        } catch (error) {
          if (error) {
            this.$toasted.show("Error Occurred ", {
              theme: "toasted-primary",
              type: "error",
              position: "bottom-center",
              duration: 2000,
            });

            this.MainView = "ErrorView";
          }
          throw error;
        }
      }
    },
    rearrangeOrder(arr) {
      const tempArr = [...arr];
      const newArr = tempArr.sort((a, b) => a.order - b.order);
      return newArr;
    },
  },
  watch: {
    getTotalPages(newVal, oldVal) {
      if (oldVal.length < newVal.length) {
        this.count++;
      }
    },
    CurrentSelectedPage() {
      this.countComp++;
    },
    
  },
};
</script>

<style>

.sideBarBtns {
  padding:8px;
  width:40px;
  height:40px;
  border-radius: 6px;
  margin-bottom:10px;
  background:#F1F1F4;
}
.sideBarBtns.selectedView {
  background:#4d1b7e;
}
.sideBarBtns.selectedView .sideBarBtnsIcon path {
fill:#ffffff  !important;
}


.sidebar {
  overflow:hidden scroll;
  scroll-behavior: smooth;
  min-height: calc(100vh - 50px);
  max-height: calc(100vh - 50px);
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.06);
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none !important;
}
.default-bg {
  background: #cccace;
}
.cursorPointer {
  cursor: pointer;
}
.div-disable {
  pointer-events: none;
  opacity: 0.4;
}
.image-division {
  height: 100%;
  cursor: pointer;
  /* display: flex;
    align-items: center;
    justify-content: center; */
}
.ql-editor {
  min-height: auto !important;
  height: auto !important;
}
.ql-container {
  height: auto !important;
  min-height: auto !important;
}
.sub-title {
  font-size: 14px;
  color: #8c898f;
}
.page-setting {
  padding: 15px 0px 0px;
}
.page-setting-btn,
.page-setting-btn i {
  color: #8c898f;
  font-weight: 500;
}
.close-icon {
  position: absolute;
  right: 23px;
  top: 23px;
  background: transparent;
  border: none;
}
.deleteBlock {
  position: absolute;
  right: 5px;
  top: 5px;
  background: transparent;
  border: none;
  z-index: 10;
}
#preview div {
  word-break: break-word !important;
}
.navbar__icon-container.active {
  background: rgb(179 175 182 / 30%) !important;
}

.big-div {
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
  padding: 0px;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 15%);
}
@media only screen and (max-width: 767px) {
  /* .big-div {
        width: 100% !important;
        padding: 0px 15px;
    } */
  .navbar__left,
  .navbar__middle,
  .navbar__right {
    margin-bottom: 20px;
  }
  .page-style {
    height: 100%;
  }
}
/* @media only screen and (max-width: 1366px) {
    .big-div {
        width: 100%!important;
        padding: 0px 15px !important;
    }
} */

.Tab:hover {
  transform: translate3d(0, -3px, 0);
  -webkit-transform: translate3d(0, -3px, 0);
}

.ImageInputBlock:hover {
  opacity: 0.6;
  /* background-color:rgba(255, 255, 255, 0.3) !important; */
}

.SelectedImageStyle {
  position: absolute;
  top: 0;
  right: 0;
  /* top: 50%;
  left: 50%;
  transform:translate(-50%,-50%); */
  /* z-index:1000; */
}

/* Range Slider CSS */


input[type="range"] {
  -webkit-appearance: none;
  appearance: none; 
  width: 100%;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  border-radius: 16px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 12px;
  background: #ccc;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 12px;
  background: #ccc;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none; 
  height: 12px;
  width: 12px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #4D1B7E;
  box-shadow: -407px 0 0 400px #4D1B7E;
}


/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 12px;
  width: 12px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #4D1B7E;
  box-shadow: -407px 0 0 400px #4D1B7E;
}

</style>

<style>
/* Radio Switch CSS */
.masterQues .custom-control-input ~ .custom-control-label::after {
  left: calc(-2.25rem + 1px) !important;
  background: #6d6b6d !important;
}
.masterQues
  .custom-control-input:checked
  ~ .custom-control-label[data-v-2c6c3e7d]::after,
.masterQues .custom-control-input ~ .custom-control-label::after {
  height: 16px !important;
  width: 16px !important;
  top: 4px !important;
}
/* .masterQues .custom-control-input:checked ~ .custom-control-label[data-v-2c6c3e7d]::before,
.masterQues .custom-control-input ~ .custom-control-label::before {
  height: 12px !important;
} */
.masterQues .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}
.masterQues .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}

/* Agile Carousel CSS */
.agile__actions {
  justify-content: center !important;
  margin-top: 10px;
}
.agile__actions button {
  background: transparent;
  border: none;
  color: #323232;
  cursor: pointer;
  font-size: 24px;
  transition-duration: 0.3s;
}
.agile__actions button:hover {
  color: #000000;
}
</style>
